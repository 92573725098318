import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {Row, Col, Spinner, Modal, ModalBody, Button, Label} from "reactstrap"
import {withRouter} from "react-router-dom"
import {withTranslation} from "react-i18next"
import {AvForm, AvField} from "availity-reactstrap-validation"
import Select from "react-select"
import {Link} from "react-router-dom"
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit"

const {SearchBar} = Search
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"

const DataTableUser = props => {
    const [loading, setLoading] = useState(false)
    const [modalUpd, setModalUpd] = useState(false)
    const [modalDel, setModalDel] = useState(false)
    const [id, setId] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [name, setName] = useState("")
    const [roles, setRoles] = useState([])
    const [selectRole, setSelectRole] = useState("")
    const [data, setData] = useState([])
    const [view, setView] = useState(0);
    const [delet, setDelet] = useState(0);
    const [edit, setEdit] = useState(0);
    const [cloture, setCloture] = useState(0);
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    useEffect(async () => {
        var cloture_annee = JSON.parse(localStorage.getItem("cloture"));
        setCloture(cloture_annee)
        const userAuth = JSON.parse(localStorage.getItem('UserMenu'));
        const typeArticleSubMenu = userAuth.find(user =>
            user.sous_menu.some(subMenu => subMenu.sous_menu === "Gestion Utilisateur")
        );
        setEdit(typeArticleSubMenu.sous_menu[0].edit)
        setView(typeArticleSubMenu.sous_menu[0].view)
        setDelet(typeArticleSubMenu.sous_menu[0].delete)
        const resR = await API.get("Role/select").then(resR => {
            setRoles(resR.data.Role)
        })
        const res = await API.post("User/list", {
            annee_id: annee_id,
        }).then(res => {
            setData(res.data.Users)
        })
        setLoading(true)
    }, [])
    const toggleUpd = el => {
        setModalUpd(!modalUpd)
        setId(el.id)
        setName(el.name)
        setEmail(el.email)
        setSelectRole(el.role)
    }
    const toggleDel = id => {
        setModalDel(!modalDel)
        setId(id)
    }
    const toggleModif = async () => {
        if (name != "" && email != "") {
            const res = await API.post("User/edit_rapide", {
                id,
                name,
                email,
                password,
                role_id: selectRole.value,
                annee_id
            }).then(res => {
                setModalUpd(!modalUpd)
                API.post("User/list", {
                    annee_id: annee_id,
                }).then(res => {
                    setData(res.data.Users)
                })
            })
        }
    }
    const toggleSupp = async () => {
        const res = await API.post("User/destroy", {
            id: id,
        }).then(res => {
            setModalDel(!modalDel)
            API.post("User/list", {
                annee_id: annee_id,
            }).then(res => {
                setData(res.data.Users)
            })
        })
    }
    const NoDataIndication = () => (
        <h6 style={{textAlign: "center"}}>No record to display</h6>
    )
    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: data.length,
        page: 1,
        nextPageText: "Suivant",
        prePageText: "Précédent",
        alwaysShowAllBtns: true,
        sizePerPage: 20,
        sizePerPageList: [
            {
                text: "5",
                value: 5,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "20",
                value: 20,
            },
            {
                text: "30",
                value: 30,
            },
            {
                text: "All",
                value: data.length,
            },
        ],
    }
    const Columns = () => [
        {
            dataField: "name",
            text: props.t("User"),
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "email",
            text: props.t("Email"),
            sort: true,
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "Modification rapide",
            isDummyField: true,
            text: props.t("Modification rapide"),
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                cloture != 1 ? (
                    edit === 1 ? (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <i
                                style={{color: "cornflowerblue", cursor: "pointer"}}
                                //className=" fas fa-user-pen"
                                className="fas fa-edit"
                                onClick={() => toggleUpd(row)}
                            ></i>
                        </div>
                    ) : (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <i
                                style={{color: "#A7A3A3", cursor: "pointer"}}
                                className="fas fa-edit"
                                title="Vos n'avez pas d'acces"
                            ></i>
                        </div>
                    )
                ) : (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <i
                            style={{color: "#A7A3A3", cursor: "pointer"}}
                            className="fas fa-edit"
                            title="Année clôturée"
                        ></i>
                    </div>
                )
            ),
        },
        {
            dataField: "Modification",
            isDummyField: true,
            text: props.t("Modification"),
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                cloture != 1 ? (
                    edit === 1 ? (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Link to={"/ModifUser?id=" + row.id}>
                                <i
                                    style={{color: "cornflowerblue", cursor: "pointer"}}
                                    className="fas fa-edit"
                                ></i>
                            </Link>
                        </div>
                    ) : (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <i
                                style={{color: "#a7a3a3", cursor: "pointer"}}
                                className="fas fa-edit"
                            ></i>
                        </div>
                    )
                ) : (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <i
                            style={{color: "#a7a3a3", cursor: "pointer"}}
                            className="fas fa-edit"
                            title="Année clôturée"
                        ></i>
                    </div>
                )

            ),
        },
        {
            dataField: "Suppression",
            isDummyField: true,
            text: props.t("Suppression"),
            style: {textAlign: "center"},
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                cloture != 1 ? (
                    delet === 1 ? (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <i
                                style={{color: "#ED6464", cursor: "pointer"}}
                                onClick={() => toggleDel(row.id)}
                                className="fas fa-trash-alt"
                            ></i>
                        </div>
                    ) : (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <i
                                style={{color: "#A7A3A3", cursor: "pointer"}}
                                className="fas fa-edit"
                                title="Vos n'avez pas d'acces"
                            ></i>
                        </div>
                    )
                ) : (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <i
                            style={{color: "#A7A3A3", cursor: "pointer"}}
                            className="fas fa-edit"
                            title="Année clôturée"
                        ></i>
                    </div>
                )

            ),
        },
    ]
    return (
        <React.Fragment>
            {loading ? (
                <Row>
                    <Col xs="12">
                        <ToolkitProvider
                            keyField="id"
                            data={data}
                            columns={Columns()}
                            search
                            bootstrap4
                        >
                            {toolkitProps => (
                                <div>
                                    <Col sm="4">
                                        <div className="search-box me-2 mb-2 d-inline-block">
                                            <div className="position-relative">
                                                <SearchBar {...toolkitProps.searchProps} />
                                                <i className="bx bx-search-alt search-icon"/>
                                            </div>
                                        </div>
                                    </Col>
                                    <BootstrapTable
                                        wrapperClasses="table-responsive"
                                        noDataIndication={() => <NoDataIndication/>}
                                        striped={false}
                                        bordered={false}
                                        classes={"table align-middle table-nowrap"}
                                        headerWrapperClasses={"table-light"}
                                        hover
                                        pagination={paginationFactory(pageOptions)}
                                        {...toolkitProps.baseProps}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    </Col>
                </Row>
            ) : (
                <div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        {" "}
                        <Spinner type="grow" className="ms-6" color="primary"/>
                    </div>
                    <h4 style={{textAlign: "center", marginTop: "2%"}} className="ms-6">
                        {" "}
                        Chargement en cours ...{" "}
                    </h4>
                </div>
            )}
            {/* <update> */}
            <Modal
                style={{
                    maxWidth: "50%",
                    width: "100%",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                }}
                isOpen={modalUpd}
                toggle={toggleUpd}
                centered={true}
            >
                <div
                    className="modal-header"
                    style={{
                        width: "100% !important",
                        justifyContent: "center !important",
                        margin: "0 auto",
                        fontSize: "17px",
                    }}
                    toggle={toggleUpd}
                >
                    {props.t("Modifier l'utilisateur")}
                </div>
                <ModalBody>
                    <AvForm>
                        <Row>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">Nom</Label>
                                    <AvField
                                        name="nom"
                                        placeholder="Inserer un nom"
                                        type="text"
                                        errorMessage="* Nom obligatoire"
                                        className="form-control"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        validate={{required: {value: true}}}
                                    />
                                </div>
                            </Col>
                            <Col lg="6">
                                <Label for="basicpill-firstname-input1">Email</Label>
                                <AvField
                                    name="email"
                                    placeholder="Enter un email valide"
                                    type="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    errorMessage="* Email invalide"
                                    validate={{
                                        required: {value: true},
                                        email: {value: true},
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <Label for="basicpill-firstname-input1">Password</Label>
                                <AvField
                                    name="password"
                                    type="password"
                                    placeholder="Inserer un password"
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </Col>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label for="basicpill-address-input1">Rôle</Label>
                                    <Select
                                        options={roles}
                                        isSearchable={true}
                                        classNamePrefix="select2-selection"
                                        onChange={setSelectRole}
                                        value={selectRole}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            {" "}
                            <Col lg="6">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                    className="text-center mt-4"
                                >
                                    <Button
                                        type="button"
                                        color="warning"
                                        className="btn btn-warning  mb-2 me-2"
                                        onClick={toggleUpd}
                                    >
                                        {props.t("Annuler")}
                                    </Button>
                                </div>
                            </Col>
                            <Col lg="6">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                    }}
                                    className="text-center mt-4"
                                >
                                    <button
                                        onClick={toggleModif}
                                        type="submit"
                                        className="btn btn-primary "
                                    >
                                        Confirmer
                                    </button>
                                </div>
                            </Col>
                        </div>
                    </AvForm>
                </ModalBody>
            </Modal>
            {/* <Delete> */}
            <Modal isOpen={modalDel} toggle={toggleDel} centered={true}>
                <div
                    className="modal-header"
                    style={{
                        width: "100% !important",
                        justifyContent: "center !important",
                        margin: "0 auto",
                        fontSize: "17px",
                    }}
                    toggle={toggleDel}
                >
                    {props.t("Suppression d'un utilisateur")}
                </div>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <p>
                                {props.t(
                                    "Êtes-Vous sûr de vouloir supprimer cet utilisateur ?"
                                )}
                            </p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    color="info"
                                    className="btn-rounded "
                                    onClick={toggleSupp}
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    {props.t("Oui")}
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggleDel}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    {props.t("Non")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}
export default withRouter(withTranslation()(DataTableUser))
DataTableUser.propTypes = {
    data: PropTypes.array,
    t: PropTypes.any,
}
